<template>
  <div class="home">
    <div class="container-fluid">
      <div class="d-flex flex-md-row flex-lg-row flex-column align-items-center">
        <div class="col-sm-6 pl-0">
          <div class="portrait">
            <div
              v-motion
              :initial="{
                x: -200,
                opacity: 0,
              }"
              :enter="{
                x: 0,
                opacity: 1,
                transition: {
                  y: {
                    delay: 1600,
                  },
                  opacity: {
                    duration: 1000,
                  },
                },
              }">
              <img class="ph rounded-4 shadow-md" draggable="false" src="../../public/img/preubatres.png" alt="">
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4">
          <h1 class=" text-warning"><VueWriter class="fs-1 d-flex justify-content-start" :array="[`I'm Valentino Ghitti`]" /></h1>
          <h1 class="fs-3 text-warning d-flex justify-content-start" > Software Developer</h1>
          <span class="d-flex justify-content-start">
            I have experience in both front-end and back-end development. My focus is on staying updated with the 𝐥𝐚𝐭𝐞𝐬𝐭 𝐭𝐞𝐜𝐡𝐧𝐨𝐥𝐨𝐠𝐢𝐞𝐬 and methodologies, allowing me to design, develop, and deploy scalable, 𝐡𝐢𝐠𝐡-𝐩𝐞𝐫𝐟𝐨𝐫𝐦𝐚𝐧𝐜𝐞 applications that meet client needs.
          </span>
          
          <div>
            <div class="col-3"></div>
            <router-link class="btn10 rounded col-4 col-xxl-3 col-md-6 col-lg-3 col-xl-3 col-md-3 d-flex justify-content-center" to="/about">About Me!</router-link>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
};

</script>

<style >
/* BG SPLIT */
body {
  height: 100vh;
  background-color: #111111;
}

.ph {
  max-width: 100%;
  height: auto;
}

.portrait {
  padding: 100px;
  background: linear-gradient(80deg, rgba(252,178,6,1) 37%, rgba(17,17,17,1) 53%, rgba(17,17,17,1) 70%);
}

h1,
VueWriter {
  font-family: 'Alfa Slab One', cursive;
}

span {
  color: white;
}

.btn10 {
  padding: 10px;
  top: 30px;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase!important;
  letter-spacing: 2px;
  text-decoration: none;
  color: #fff;
  cursor: hand;
  text-align: center;
  border: 1px solid #fff;
  position: relative;
  overflow: hidden!important;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  background: transparent!important;
  z-index: 10;
}

.btn10:hover {
  border: 1px solid rgb(0, 0, 0);
  color: #000000!important;
}

.btn10::before,
.btn10::after {
  content: '';
  width: 0%;
  height: 100%;
  display: block;
  position: absolute;
  -ms-transform: skewX(-20deg);
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
  left: -10%;
  top: 0;
  box-shadow: 2px 0px 14px rgb(0, 0, 0);
}

.btn10::before {
  background: #fcb206;
  z-index: -12;
  opacity: 1;
  -moz-transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
}

.btn10::after {
  background: #b98a1d;
  z-index: -15;
  opacity: 0;
  -webkit-transition: all .94s cubic-bezier(.2,.95,.57,.99);
  -moz-transition: all .4s cubic-bezier(.2,.95,.57,.99);
  -o-transition: all .4s cubic-bezier(.2,.95,.57,.99);
  transition: all .4s cubic-bezier(.2,.95,.57,.99);
}

.btn10:hover::before,
.btn10:hover::after {
  opacity: 1;
  width: 116%;
}

.btn10:hover::after,
.btn10:hover::after {
  opacity: 1;
  width: 120%;
}


@media screen and (max-width: 320px),
(max-width: 375px),
(max-width: 425px) {
  .portrait {
    display: none;
  }
}

@media screen and (max-width: 688px){
  .portrait {
    display: none;
  }
}


@media screen and (max-width: 768px) {
  .ph {
    height: 10px;
    max-height: 400px;
  }

  .portrait {
    padding: 10px;
  }
  
  .portrait img {
    height: 400px;
  }
}

@media screen and (max-width: 834px) {
  .ph {
    height: 450px;
    max-height: 450px;
  }
}

@media screen and (max-width: 1472px) {
  .ph {
    height: 450px;
    max-height: 450px;
  }
}


@media screen and (max-width: 1500px) {
  .ph {
    height: 450px;
    max-height: 450px;
  }
}

@media screen and (max-width: 1200px) {
  .ph {
    height: 400px;
    max-height: 450px;
  }
}

@media screen and (max-width: 1370px) {
  .ph {
    height: 380px;
    max-height: 450px;
  }
}

@media screen and (max-width: 1050px) {
  .ph {
    height: 330px;
    max-height: 450px;
  }
}

@media screen and (min-width: 1821px) and (max-width: 2560px) and (min-resolution: 192dpi) {
  .ph {
    height: 900px;
    max-height: 1000px;
  }
  span {
    font-size: 30px;
  }
}


@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .ph {
    height: 750px;
    max-height: 800px;
  }

}

@media screen and (min-width: 2400px) and (max-width: 3200px) {
  .ph {
    height: 600px;
    max-height: 700px;
  }

}

@media screen and (min-width: 2133px) and (max-width: 2844px) {
  .ph {
    height: 650px;
    max-height: 750px;
  }

}


@media screen and (max-width: 425px) {
  .ph {
    max-height: 300px;
  }
}

@media screen and (max-width: 375px) {
  .ph {
    max-height: 250px;
  }
}

@media screen and (max-width: 320px) {
  .ph {
    max-height: 200px;
  }
}

</style>