<template>
  <div class="about">
    <div class="container-fluid">
      <div class="row">
        <div class="col-4"></div>
        <div class="col-1">
          <h1 class="muted position-absolute mt-5 pt-5 top-0 start-50 translate-middle fondo">{{ title }}</h1>
          <h1 class="text-white position-absolute mt-5 pt-5 top-0 start-50 translate-middle">{{ titlebg }} <span class="text-warning">{{secondTitle}}</span></h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    title: String,
    secondTitle: String,
    titlebg: String
  }
}
</script>

<style>
.muted {
  color: rgb(49, 49, 49);
}
</style>