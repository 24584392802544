<template>
  <div class="container-fluid p-0" id="nav">
    <nav class="navbar navbar-expand-lg bg-warning d-lg-none ">
      <div class="container-fluid">
        <a class="navbar-brand" href="/"><img height="60" src=".././public/img/logocv.svg" alt=""></a>
        <a class="navbar-brand" v-show="$route.path === '/'"><h1 class="fs-4">Home </h1></a>
        <a class="navbar-brand" v-show="$route.path === '/about'"><h1 class="fs-4">About me </h1></a>
        <a class="navbar-brand" v-show="$route.path === '/stack'"><h1 class="fs-4">My Stack </h1></a>
        <a class="navbar-brand" v-show="$route.path === '/skills'"><h1 class="fs-4">Experience </h1></a>
        <a class="navbar-brand" v-show="$route.path === '/projects'"><h1 class="fs-4">My Projects </h1></a>
        <a class="navbar-brand" v-show="$route.path === '/contact'"><h1 class="fs-4">Contact </h1></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" @click="toggleMenu">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" :class="{ show: isMenuOpen }" >
          <ul class="navbar-nav">
            <li class="nav-item d-flex justify-content-center" >
              <router-link class="text-decoration-none nav-link " :class="{ show: isMenuOpen }" to="/" >
                <h1 class="fs-5 " :class="{ show: isMenuOpen }">Home</h1>
              </router-link>
            </li>
            <li class="nav-item d-flex justify-content-center">
              <router-link 
                class="text-decoration-none nav-link" 
                :class="{ active: $route.path === '/about' }" 
                to="/about"
              >
                <h1 class="fs-5  ">About me</h1>
              </router-link>
            </li>
            <li class="nav-item d-flex justify-content-center ">
              <router-link class="text-decoration-none nav-link" :class="{ active: $route.path === '/stack' }" to="/stack" >
                <h1 class="fs-5 ">My Stack</h1>
              </router-link>
            </li>
            <li class="d-flex justify-content-center nav-link">
              <router-link class="text-decoration-none nav-link" :class="{ active: $route.path === '/skills' }" to="/skills" >
                <h1 class="fs-5 ">My Experience</h1>
              </router-link>
            </li>
            <li class="nav-item d-flex justify-content-center">
              <router-link class="text-decoration-none nav-link" :class="{ active: $route.path === '/contact' }" to="/contact" >
                <h1 class="fs-5 ">Contact</h1>
              </router-link>
            </li>
            <li class="nav-item d-flex justify-content-center">
              <router-link class="text-decoration-none nav-link" :class="{ active: $route.path === '/projects', }" to="/projects" >
                <h1 class="fs-5 ">My Projects</h1>
              </router-link>
            </li>
          </ul>
        </div>
      </div>

    </nav>
    <svg class="d-lg-none d-md-none d-xl-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#fcb206" fill-opacity="1" d="M0,224L480,256L960,32L1440,32L1440,0L960,0L480,0L0,0Z"></path>
    </svg>

    <Title 
      title="BEHINDTHESCENES"
      titlebg="About"
      secondTitle="Me"
      class="prueba"
      v-show="$route.path === '/about'"
    />
    <Title 
      title="MYJOURNEYSOFAR"
      titlebg="My"
      secondTitle="Experience"
      class="prueba"

      v-show="$route.path === '/skills'"
    />
    <Title 
      title="DROPMEALINE"
      titlebg="Get in"
      secondTitle="touch!"
      class="prueba"
      v-show="$route.path === '/contact'"
    />
    <Title 
      title="CODEVAULT/GITHUB"
      titlebg="My"
      secondTitle="Projects"
      class="prueba"
      v-show="$route.path === '/projects'"
    />
    <Title 
      title="TOOLSOFTHETRADE"
      titlebg="My"
      secondTitle="Stack"
      class="prueba"
      v-show="$route.path === '/stack'"
    />
    <div class="">
      <div class="content">
        <div class="col-12">
          <router-view/>
        </div>
        <div class="lol position-absolute top-50 end-0 translate-middle-y ">
          <ul class="social-icons">
            <li><router-link class="facebook" to="/" exact><i class="bi bi-house-door-fill"></i></router-link></li>
            <li><router-link class="twitter" to="/projects" exact><i class="bi bi-trophy-fill"></i></router-link></li>
            <li><router-link class="twitter" to="/about" exact><i class="bi bi-person-fill"></i></router-link></li>
            <li><router-link class="twitter" to="/stack" exact><i class="bi bi-hammer"></i></router-link></li>
            <li><router-link class="twitter" to="/skills" exact><i class="bi bi-bag-dash-fill"></i></router-link></li>
            <li><router-link class="twitter" to="/contact" exact><i class="bi bi-envelope-fill"></i></router-link></li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Title from './components/Title.vue'
export default {
  components: {
    Title
  },
  data() {
    return {
      isMenuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
}

</script>

<style >
body {
  background: linear-gradient(to left, #111111 0%, #111111 76%, #111111 76%, #111111 100%);
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

.active-link {
  text-decoration: underline;
  text-decoration-color: red;
}

a {
  text-decoration: none;
}

.lol {
  padding: 15px;
}

.content {
  display: flex;
  height: 100vh;
  align-items: center;
}

.social-icons {
  display: flex;
  align-items: center;
  flex-flow: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}

.social-icons a {
  background-color: #272828;
  color: #ffff;
  font-size: 30px;
  display: inline-block;
  line-height: 70px;
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: 100%;
  transition: all 0.2s linear;
}

.social-icons a.router-link-exact-active,
.social-icons a.router-link-active,
.social-icons a.facebook:hover,
.social-icons a:hover,
.social-icons a.router-link-exact-active:hover,
.social-icons a.router-link-active:hover {
  color: white;
  background-color: #fcb206;
}

@media screen and (max-width: 320px),
  (max-width: 375px),
  (max-width: 425px),
  (max-width: 768px) {
  .content {
    display: flex;
    height: 100vh;
  }

  .social-icons,
  .prueba {
    display: none;
  }
}

</style>
