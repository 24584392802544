import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.js'
import VueWriter from "vue-writer";
import { MotionPlugin } from '@vueuse/motion'


createApp(App).use(router).use(VueWriter).use(MotionPlugin).mount('#app')
